<template>
  <div class="biao-ti">
    <div class="xian-tiao">
      <div></div>
    </div>
    <div class="biao-ti-name">
      <slot></slot>
    </div>
    <div class="xian-tiao">
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FenGeXian"
}
</script>

<style lang="less" scoped>
.biao-ti {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  padding: 20px 0;
  .biao-ti-name{
    font-size: 30px;
    padding: 0 50px;
  }
  .xian-tiao {
    width: 166px;

    div {
      height: 1px;
      width: 100%;
      background: #000;
    }
  }
}
</style>
