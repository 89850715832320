<template>
  <div>
    <!--banner开始 -->
    <div class="banner_box">
      <div class="banLeft wid">
        <ul class="leftNav">
          <li style="height: 60px;" v-for="item in leftNav" :key="item.id">
            <a :href="item.href" :target="item.target" style="line-height: 61px;">
              - &nbsp;&nbsp;&nbsp;{{ item.name }}<i class="fa fa-angle-right i2" style="line-height: 56px;"></i>
            </a>
            <dl v-if="item.children && item.children.length > 0">
              <dd v-for="i in item.children" :key="i.id">
                <a :href="i.href" :target="i.target" style="line-height: 61px;">{{ i.name }}</a>
              </dd>
            </dl>
          </li>
        </ul>

      </div>
      <div class="banner">
        <div id="yc-mod-slider">
          <div class="wrapper">
            <div id="slideshow" class="box_skitter fn-clear">
              <ul>
                <li v-for="(item, key) in banner" :key="key">
                  <img :src="item.image" alt="">
                  <!--                  <a :href="item.href"></a>-->
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--banner结束 -->
    <!-- 案例 -->
    <!-- End 案例-->
    <div class="main wid" id="clear">

      <!--      最新动态-->
      <div class="middle" style="width: 35%;">
        <div class="indexTit">
          <a href="javascript:;" @click="$router.push('/news')">
            <i class="fa fa-circle-o"></i>最新动态</a>
        </div>
        <ul class="indexNews">
          <li v-if="newsOne" >
            <div class="ttLeft" v-if="newsOne.image">
              <img
                style="cursor: pointer"
                @click="$router.push('/news/detail/' + newsOne.id)"
                :src="newsOne.image.length > 0 ? newsOne.image[0].url : ''" :alt="newsOne.name"
              ></div>
            <div class="ttRight">
              <div  >
                <a href="javascript:" style="float: none;cursor: pointer" @click="$router.push('/news/detail/' + newsOne.id)"> · {{ newsOne.name }}</a>
              </div>
              <div class="idate" style="cursor: pointer"  @click="$router.push('/news/detail/' + newsOne.id)" >
                {{ newsOne.create_time }}
              </div>
              <div style="cursor: pointer" @click="$router.push('/news/detail/' + newsOne.id)">{{ newsOne.jian_jie }}</div>
            </div>
          </li>
          <li v-for="(item) in news" :key="item.id">
            <div>
              <a href="javascript:;" @click="$router.push('/news/detail/' + item.id)"> ·
                {{ item.name.substr(0, 20) + "..." }}
              </a>
              <div class="idate"><i class="icon-time"></i>&nbsp;{{ item.create_time }}</div>
            </div>
          </li>
        </ul>
      </div>

      <div class="left" style="margin-top: 50px;width: 35%  ">
        <ul class="indexNews">
          <li v-for="(item) in news_2" :key="item.id">
            <div>
              <a href="javascript:;" @click="$router.push('/news/detail/' + item.id)"> ·
                {{ item.name.substr(0, 20) + "..." }}
              </a>
              <div class="idate"><i class="icon-time"></i>&nbsp;{{ item.create_time }}</div>
            </div>
          </li>
        </ul>
      </div>


      <div class="right" style="width: 25%;">
        <div class="indexTit">
          <a href="javascript:;"><i class="fa fa-circle-o"></i>快速入口</a>
        </div>
        <ul class="kuai">
          <li>
            <a href="https://www.cybertonia.cn/#/firmDetail?EID=2#certificate">
              <i class="fa fa-volume-control-phone"></i>
              <p>咨询服务</p>
            </a>
          </li>
          <li>
            <a href="https://www.cybertonia.cn/#/firmDetail?EID=2#case">
              <i class="fa fa-flag"></i>
              <p>成功案例</p>
            </a>
          </li>
          <li>
            <a href="https://www.cybertonia.cn/#/service">
              <i class="fa fa-handshake-o"></i>
              <p>商务合作</p>
            </a>
          </li>
          <li>
            <a href="javascript:;" @click="$router.push('contact/us')">
              <i class="fa fa-envelope-open"></i>
              <p>联系我们</p>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="hui-bei-jin">
      <fen-ge-xian>成功案例</fen-ge-xian>
      <div class="m3_" id="clear">
        <div class="m3 wid" id="clear">
          <!--产品轮播-->
          <div class="case">
            <div class="con blkphotopicx">
              <div id="LeftArr1" class="left btn"><i class="el-icon-arrow-left"></i></div>
              <div id="ISL_Cont_11" class="center Cont" style="width: 1200px; overflow: hidden;">

                <div class="box" v-for="(item, key) in caseList" :key="key">
                  <div class="fang" :title="item.title">
                    <div class="picfit">
                      <a :href="item.url">
                        <img v-if="item.image.length > 0" :src="item.image[0].url">
                        <div class="word">
                          <p class="p2">{{ item.subTitle }}</p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div id="RightArr1" class="right btn"><i class="el-icon-arrow-right"></i></div>
            </div>
          </div>
          <!---->
        </div>
      </div>
    </div>

    <div class="friendLink wid">
      <i class="fa fa-link"></i>
      合作客户
      <p class="p1"></p>
      <!--      <p class="p2"></p>-->
      <a id="anniu">+&nbsp;显示更多</a>
    </div>
    <ul class="kehu wid" id="clear" style="padding-bottom: 20px">
      <li v-for="(item, key) in links" :key="key">
        <a :title="item.title">
          <img :src="item.image">
        </a>
      </li>
    </ul>





    <div class="hui-bei-jin">
      <fen-ge-xian>公司介绍</fen-ge-xian>
      <div class="main wid gong-si-jie-shao">
        <div class="info">
          <div class="indexTit">
            <a href="https://www.cybertonia.cn/#/firmDetail?EID=2">
              <i class="fa fa-circle-o"></i>
              公司简介
            </a>
          </div>
          <div class="leftText">
            <p>
              {{ info.gong_ke_yuan_jian_jie ? info.gong_ke_yuan_jian_jie.value : '' }}
            </p>
            <div style="height: 30px;"></div>
            <a class="e_btn" href="https://www.cybertonia.cn/#/firmDetail?EID=2">
              了解更多
            </a>
          </div>
        </div>
        <div class="zhu-tu">
          <img :src="info.gong_ke_yuan_jian_jie_tu ? info.gong_ke_yuan_jian_jie_tu.value : ''">
        </div>
      </div>
    </div>





    <!-- -->
    <!-- -->
  </div>
</template>

<script>
import FenGeXian from "@/components/FenGeXian";

export default {
  components: {FenGeXian},
  data() {
    return {
      banner: [],
      caseList: [],
      links: [],
      news: [],
      news_2:[],
      newsOne: {},
      info: {},
      leftNav: [],
    }
  },
  mounted() {
    this.getBanner()
    this.getCaseList()
    this.getLinks()
    this.getNews()
    this.getPublicData()
    this.getLeftNav()
  },
  methods: {
    getCaseList() {
      this.$api.getCase({page: 1, data_length: 12, order_field: 'order_list', order_type: 'asc'}).then(res => {
        const caseList = res.data.data;
        caseList.forEach(item => {
          let str = item.title
          if (str && str.length > 10) {
            str = str.substr(0, 12) + "..."
          }
          item.subTitle = str
        })
        this.caseList = caseList
        this.$nextTick(() => {
          // 成功案例
          var scrollPic_03 = new ScrollPic();
          scrollPic_03.scrollContId = "ISL_Cont_11"; //内容容器ID
          scrollPic_03.arrLeftId = "LeftArr1";//左箭头ID
          scrollPic_03.arrRightId = "RightArr1"; //右箭头ID
          scrollPic_03.frameWidth = 1200;//显示框宽度
          scrollPic_03.pageWidth = 404; //翻页宽度
          scrollPic_03.speed = 10; //移动速度(单位毫秒，越小越快)
          scrollPic_03.space = 5; //每次移动像素(单位px，越大越快)
          scrollPic_03.autoPlay = true; //自动播放
          scrollPic_03.autoPlayTime = 3; //自动播放间隔时间(秒)
          scrollPic_03.initialize(); //初始化
        })
      })
    },
    getBanner() {
      this.$api.getWenZhang({type_id: 33, data_length: 10, page: 1}).then(res => {
        const banner = res.data.data;
        const bn = []
        const jpg = 'http://2005265286.a.site.cn/repository/image/Hfj91D98QdCOUw1j6Sr88A.jpg';
        banner.forEach(item => {
          bn.push({
            image: item.image.length > 0 ? item.image[0].url : jpg,
            // href: item.href ? item.href : 'javascript:;'
          })
        })
        this.banner = bn
        this.$nextTick(() => {
          // banner轮播图
          //https://github.com/thiagosf/skitter
          jQuery.noConflict();
          jQuery('#slideshow').skitter({
            width: 980,
            height: 318,
            animation: 'random',
            structure: '<a href="#" class="prev_button">prev</a>' + '<a href="#" class="next_button">next</a>' + '<span class="info_slide"></span>' + '<div class="container_skitter">' + '<div class="image">' + '<a target="_blank" href="/"><img class="image_main" /></a>' + '<div class="label_skitter"></div>' + '</div>' + '</div>',
            velocity: 1.3,
            interval: 3500,
            thumb_width: '70px',
            thumb_height: '40px',
            caption: 'bottom',
            caption_width: '250',
            navigation: 1,
            fullscreen: false,
          })
          // End banner轮播图
        })
      })
    },
    getLinks() {
      this.$api.getWenZhang({type_id: 34, data_length: 12, page: 1}).then(res => {
        const links = res.data.data;
        const data = []
        const jpg = 'http://2005265286.a.site.cn/repository/image/Hfj91D98QdCOUw1j6Sr88A.jpg';
        links.forEach(item => {
          data.push({
            image: item.image.length > 0 ? item.image[0].url : jpg,
            title: item.name,
          })
        })
        this.links = data
      })
    },
    getNews() {
      this.$api.getWenZhang({type_id: 32, data_length: 16, page: 1}).then(res => {
        const news = res.data.data;
        if (news.length <= 0) {
          return
        }
        this.newsOne = news[0]
        this.news = news.slice(1,5)
        this.news_2 = news.slice(5,16)
        console.log( this.news )
        console.log( this.news_2 )


      })
   /*   this.$api.getWenZhang({type_id: 32, data_length: 5, page: 2}).then(res => {
        const news = res.data.data;
        if (news.length <= 0) {
          return
        }
        this.newsOne = news[0]
        news.splice(0,1)
        this.news = news
      })*/
    },
    getPublicData() {
      this.$api.getPublicData({name: '工科院基本信息'}).then(res => {
        this.info = res.data
      })
    },
    getLeftNav() {
      this.$api.getType().then(res => {
        this.leftNav = res.data
      })
    },
  }
}
</script>
<style scoped lang="less">
.gong-si-jie-shao {
  display: flex;
  flex-direction: row;
  align-items: center;

  .zhu-tu {
    img {
      width: 400px;
    }

    //width: 50%;
    padding-left: 80px;
  }

  .info {
    .indexTit {
      a {
        font-size: 26px;
      }
    }

    font-size: 16px;

    .leftText {
      width: 100%;

      p {
        font-size: 16px;
        line-height: 30px;
      }
    }
  }
}

.hui-bei-jin {
  background-color: rgb(245, 245, 245);
  padding-bottom: 30px
}

.an-li-biao-ti {
  font-size: 20px;

}

#LeftArr1, #RightArr1 {
  background: rgba(0, 0, 0, 0.36);
}

.e_btn {
  border-color: rgb(0, 95, 179);
  width: 150px;
  height: 44px;
  color: rgb(0, 95, 179);
  border-width: 1px;
  border-style: solid;
  background: none;
  text-align: center;
  min-height: inherit;
  border-radius: 5px;
  padding: 10px 30px;
}
</style>
