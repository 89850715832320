import { render, staticRenderFns } from "./FenGeXian.vue?vue&type=template&id=64938d18&scoped=true&"
import script from "./FenGeXian.vue?vue&type=script&lang=js&"
export * from "./FenGeXian.vue?vue&type=script&lang=js&"
import style0 from "./FenGeXian.vue?vue&type=style&index=0&id=64938d18&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64938d18",
  null
  
)

export default component.exports